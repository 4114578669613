import { model as config, remote } from '@/config'
import { playerInfo } from '@setplex/player'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import { not } from 'patronum'
import { v4 as uuidv4 } from 'uuid'
import { env } from '~/app/environment'
import { model as session } from '~/entities/session'
import { PATH, type ContentTypesWithEpg } from '~/shared/constants'
import { postpone } from '~/shared/effector'
import { F, getIdFromUrl, T } from '~/shared/helpers'
import { logger } from '~/shared/logger'
import { addScriptAsync } from '~/shared/tools/script'
import { type Goog, type NonceLoader, type NonceManager } from './index.h'

declare const goog: Goog

export const init: EventCallable<void> = createEvent()
const awaited: EventCallable<void> = createEvent()

export const checkIfNeedToLoadNonce: EventCallable<{
  id: number
  type: ContentTypesWithEpg
}> = createEvent()

export const $enabled: Store<boolean> = config.get(
  remote.uvo_isGooglePalEnabled
)
// TODO: store that used in unused function
export const $aniviewParam: StoreWritable<string> = createStore(
  env.UVO_GOOGLE_PAL_ANYVIEW_PARAM_NAME
)

export const $ready = createStore<boolean>(false)

// Google PAL SDK script loader
export const loadGooglePalSdkFx = createEffect(async () => {
  await addScriptAsync('//imasdk.googleapis.com/pal/sdkloader/pal.js')
  if (!('goog' in window && window.goog != null && goog.pal != null)) {
    throw new Error('`goog.pal` is not defined')
  }
})

// nonce loader
export const createNonceLoaderFx = createEffect(() => {
  const consentSettings = new goog.pal.ConsentSettings()
  return new goog.pal.NonceLoader(consentSettings)
})

export const $nonceLoader: StoreWritable<NonceLoader | null> = restore(
  createNonceLoaderFx.doneData,
  null
)

// nonce manager
export const loadNonceManagerFx = attach({
  source: { loader: $nonceLoader, user: session.$info },
  effect: ({ loader, user }) => {
    if (!loader) return null

    const request = new goog.pal.NonceRequest()

    let descriptionUrl = ''
    let url = ''

    const { pathname = '', search = '' } = new URL(window.location.href)
    const h = pathname.replace(/\/play$/, '')
    const id = getIdFromUrl(h) || Number(h.split('/').pop()) || null

    if (pathname.includes(PATH.MOVIE)) {
      descriptionUrl = id
        ? `${window.location.origin}${PATH.MOVIE}/${id}${search}`
        : ''
      url = id
        ? `${window.location.origin}${PATH.MOVIE}/${id}${PATH.PLAY}${search}`
        : ''
    }

    if (pathname.includes(PATH.LIVE)) {
      const urlToSet = id
        ? `${window.location.origin}${PATH.LIVE}/${id}${PATH.PLAY}${search}`
        : ''
      descriptionUrl = urlToSet
      url = urlToSet
    }

    // https://docs.google.com/spreadsheets/d/1pfWja03bUAxhaulNJaIvZ_nBM2-TuBl6uQk9HHeNLBE/edit#gid=0
    request.adWillAutoPlay = true
    request.adWillPlayMuted = false
    request.continuousPlayback = true
    request.descriptionUrl = descriptionUrl // url without /play part
    request.iconsSupported = true
    request.omidVersion = '1.0.0' // ??? static for now
    request.omidPartnerVersion = '1.2.3' // ??? static for now
    request.omidPartnerName = 'aniview' // ??? static for now
    request.playerType = playerInfo.name
    request.playerVersion = playerInfo.version
    request.ppid = user?.userGuid ?? ''
    request.sessionId = uuidv4()
    request.supportedApiFrameworks = [] // will add 7 nonetheless, because of `omid...` params
    request.url = url
    request.videoHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight // there is no player container before nonce getting
    request.videoWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    return loader.loadNonceManager(request)
  },
})

export const $nonceManager: StoreWritable<NonceManager | null> = restore(
  loadNonceManagerFx.doneData,
  null
)

// generate nonce
const getNonceFx = attach({
  source: $nonceManager,
  effect: (manager) => {
    if (manager) return manager.getNonce()
    throw new Error('nonce manager is not loaded')
  },
})

//
// init, load sdk, create nonce loader
//

postpone({
  clock: init,
  until: $enabled,
  target: awaited,
})

// when enabled -> load (or not) google pal sdk
sample({
  clock: awaited,
  filter: not($ready), // do not load script twice
  target: loadGooglePalSdkFx,
})

loadGooglePalSdkFx.failData.watch((error) => {
  logger.error('failed to load Google PAL SDK:', error)
})

// when google pal sdk is loaded -> create nonce loader
sample({
  clock: loadGooglePalSdkFx.done,
  fn: T,
  target: [$ready, createNonceLoaderFx],
})

createNonceLoaderFx.failData.watch((error) => {
  logger.error('failed to create Google PAL nonce loader:', error)
})

//
// generate nonce
//

export const generateNonceFx = createEffect(async () => {
  await loadNonceManagerFx()
  return await getNonceFx()
})

export const resetIdType = createEvent()

// store for id and type of content, for example movie with entityId = 1 => { id: 1, type: contentTypesWithEpg.movie }
export const $idType = createStore<{
  id: number
  type: ContentTypesWithEpg
} | null>(null).reset(resetIdType)

sample({
  clock: checkIfNeedToLoadNonce,
  source: $idType,
  filter: (idType, newData) => {
    return idType?.id !== newData.id || idType?.type !== newData.type
  },
  fn: (_, newData) => newData,
  target: $idType,
})

// Generate nonce if session data is ready or session data request is failed, and if loader is ready
sample({
  clock: [session.$info, $idType, $nonceLoader],
  source: {
    idType: $idType,
    loader: $nonceLoader,
  },
  filter: ({ idType, loader }) => Boolean(idType?.id) && Boolean(loader),
  fn: ({ idType, loader }) => ({ id: idType?.id, loader }),
  target: generateNonceFx,
})

export const $nonce = createStore<string>('').on(
  generateNonceFx.doneData,
  (_, nonce) => nonce
)

// If ad blocker is enabled loadGooglePalSdkFx effect will fail
export const $isScriptLoadingFailed: StoreWritable<boolean> = createStore(false)
  .on(init, F)
  .on(loadGooglePalSdkFx, F)
  .on(loadGooglePalSdkFx.fail, T)

// Check if nonce is already loaded or need to load
export const $waitingForNonce = createStore<boolean>(false)
  .on(init, T)
  /* No need to wait nonce if any effect is failed */
  .on(
    [
      createNonceLoaderFx.fail,
      loadGooglePalSdkFx.fail,
      loadNonceManagerFx.fail,
      generateNonceFx.fail,
    ],
    F
  )
  .on(generateNonceFx.doneData, F)

// Check whether you need to wait for nonce depending on the Firebase flag
sample({
  clock: config.$ready,
  source: {
    enabled: $enabled,
    waitingForNonce: $waitingForNonce,
  },
  filter: config.$ready,
  fn: ({ enabled, waitingForNonce }) => (waitingForNonce ? enabled : false),
  target: $waitingForNonce,
})

// Need to load new nonce when go to another movie/liveChannel
sample({
  clock: $idType,
  source: {
    enabled: $enabled,
    isFailed: $isScriptLoadingFailed,
  },
  filter: ({ enabled, isFailed }) => enabled && !isFailed,
  fn: T,
  target: $waitingForNonce,
})
